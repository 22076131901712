<template>
    <div class="pc arrivals">

        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>

        <header class="p-3">
            <div class="row align-items-center mb-2">
                <div class="col-6">
                    <h1 v-cloak>{{ translator.arribades }}</h1>
                </div>
                <div class="col-6 text-right">
                    <div v-if="beforeVisibleArrivals.length > 0" class="text-right">
                        <button v-if="isBeforeDay" @click="isBeforeDay = !isBeforeDay" class="btn btn-outline-light btn-sm cambiar-listado">{{ translator.arribades }}</button>
                        <button v-else @click="isBeforeDay = !isBeforeDay" class="btn btn-outline-light btn-sm cambiar-listado">{{ translator.diaanterior }}</button>
                    </div>
                </div>
            </div>

            <!-- Input de búsqueda -->
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fas fa-search"></i>
                    </div>
                </div>
                <input type="text"
                       class="form-control"
                       id="inlineFormInputGroupUsername2"
                       @keyup.enter="searchReservation($event)"
                       :placeholder="translator.cercarreserves"
                       autocomplete="off" />
            </div>
        </header>

        <div v-if="visibleArrivals.length == 0 && !isPreLoad" v-cloak>
            <h5 class="p-3">0 {{ translator.arribades }}</h5>
        </div>

        <!-- Listado día anterior -->
        <div v-if="isBeforeDay">
            <div class="container llegadas-listado px-4"
                v-for="(day, index) in listBeforeDay"
                :key="index"
                v-cloak>
                <div data-toggle="collapse"
                    :data-target="'#booking' + index"
                    aria-expanded="true"
                    :aria-controls="'#booking' + index"
                    class="fecha-llegada"
                    style="cursor: pointer; display: flex; justify-content: space-between"
                    @click="day.expanded === true ? day.expanded = false : day.expanded = true"
                    v-cloak>
                    {{ day.day }}
                    <i v-if="day.expanded" style="font-size: 18px;" class="arrow fal fa-angle-up"></i>
                    <i v-else style="font-size: 18px;" class="arrow fal fa-angle-down"></i>
                </div>

                <div v-for="(booking, index1) in beforeVisibleArrivals"
                    :key="index1"
                    class="collapse multi-collapse show"
                    :id="'booking' + index">
                    <div v-show="day.day == booking._arrival_date">
                        <router-link class="card card-llegadas mb-2"
                                    :to="{ name: 'Booking', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}"
                                    v-cloak>
                            <div class="card-body shadow-sm">
                                <h5 class="card-title" v-cloak>
                                    {{ booking.lodging_name }} - {{ booking.reservation }}
                                    <small v-if="booking.arrival_time.length > 0" v-cloak>{{ booking.arrival_time }} h</small>
                                </h5>
                                <p v-cloak>
                                    {{ booking.nights }}
                                    <span v-if="booking.nights == 1" v-cloak>
                                        {{ translator.nit | toLower }}
                                    </span>
                                    <span v-else v-cloak>{{ translator.nits | toLower }}</span>

                                    - {{ booking.guests }}

                                    <span v-if="booking.guests == 1" v-cloak>
                                        {{ translator.hoste | toLower }}
                                    </span>
                                    <span v-else v-cloak>{{ translator.hostes | toLower }}</span>

                                    ({{ booking.adults }}-{{ booking.children }}-{{ booking.babies }})
                                </p>
                                <p class="small" v-cloak>
                                    {{ booking.customer_name }}
                                </p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- Listado de normal -->
        <div v-else>
            <div class="container llegadas-listado px-4"
                v-for="(day, index) in listDays"
                :key="index"
                v-cloak>
                <div data-toggle="collapse"
                    :data-target="'#booking' + index"
                    aria-expanded="true"
                    :aria-controls="'#booking' + index"
                    class="fecha-llegada"
                    style="cursor: pointer; display: flex; justify-content: space-between"
                    @click="day.expanded === true ? day.expanded = false : day.expanded = true"
                    v-cloak>
                    {{ day.day }}
                    <i v-if="day.expanded" style="font-size: 18px;" class="arrow fal fa-angle-up"></i>
                    <i v-else style="font-size: 18px;" class="arrow fal fa-angle-down"></i>
                </div>

                <div v-for="(booking, index1) in visibleArrivals"
                    :key="index1"
                    class="collapse multi-collapse show"
                    :id="'booking' + index">
                    <div v-show="day.day == booking._arrival_date">
                        <router-link class="card card-llegadas mb-2"
                                    :to="{ name: 'Booking', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}"
                                    v-cloak>
                            <div class="card-body shadow-sm">
                                <h5 class="card-title" v-cloak>
                                    {{ booking.lodging_name }} - {{ booking.reservation }}
                                    <small v-if="booking.arrival_time.length > 0" v-cloak>{{ booking.arrival_time }} h</small>
                                </h5>
                                <p v-cloak>
                                    {{ booking.nights }}
                                    <span v-if="booking.nights == 1" v-cloak>
                                        {{ translator.nit | toLower }}
                                    </span>
                                    <span v-else v-cloak>{{ translator.nits | toLower }}</span>

                                    - {{ booking.guests }}

                                    <span v-if="booking.guests == 1" v-cloak>
                                        {{ translator.hoste | toLower }}
                                    </span>
                                    <span v-else v-cloak>{{ translator.hostes | toLower }}</span>

                                    ({{ booking.adults }}-{{ booking.children }}-{{ booking.babies }})
                                </p>
                                <p class="small" v-cloak>
                                    {{ booking.customer_name }}
                                </p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <NavbarCheckin :translator="translator" :user="user" />
    </div>
</template>

<script>
    document.addEventListener("DOMContentLoaded", () => {
        document.querySelectorAll("input[type=text]").forEach((node) =>
            node.addEventListener("keypress", (e) => {
                if (e.keyCode == 13) {
                    e.preventDefault();
                }
            })
        );
    });

    import NavbarCheckin from "@/components/NavbarCheckin.vue";
    import { mapState } from "vuex";

    export default {
        name: "Arrivals",
        components: {
            NavbarCheckin,
        },

        data() {
            return {
                isPreLoad: false,
                isBeforeDay: false,
                visibleArrivals: [],
                beforeVisibleArrivals: [],
                listDays: [],
                listBeforeDay: [],
                searchReference: "",
            };
        },

        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("checkin", ["arrivals"]),
        },

        watch: {
            translator() {
                this.$store.dispatch("checkin/getArrivals", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            },
            arrivals(newQ) {
                this.resetVisibleBookings(newQ);
                sessionStorage.setItem('listado', 'Arrivals');
                this.isPreLoad = false;
            },
        },

        async created() {
            this.isPreLoad = true;
            try {
                this.translator 
                    ? this.$store.dispatch("checkin/getArrivals", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') })
                    : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            } catch (error) {
                console.error(error);
            } finally {
                //this.isPreLoad = false;
            }
        },

        methods: {
            resetVisibleBookings(arrivals) {
                this.visibleArrivals = [];
                this.beforeVisibleArrivals = []
                this.listDays = [];
                this.listBeforeDay = [];
                
                const formatDate = (date) => date.toISOString().slice(0, 10);
                const fechaHoy = formatDate(new Date());   

                arrivals.forEach(booking => {
                    booking.guests = parseInt(booking.adults) + parseInt(booking.children) + parseInt(booking.babies);
                    booking._arrival_date = this.formatDate(booking.arrival_date);
                    booking._dayHour = new Date(`${this.formatDate(booking.arrival_date)} ${booking.arrival_time}`);
                    let addDay = true;
                    let arrivalTime = booking.arrival_time != '' ? booking.arrival_time : '00:00';
                    //Reservas de hoy en adelante
                    if ( Date.parse(booking.arrival_date) >= Date.parse(fechaHoy) ) {    
                        
                        this.visibleArrivals.push({...booking, _order: new Date(`${booking.arrival_date} ${arrivalTime}`)});
                        this.listDays.forEach(day => {
                            day.day === booking._arrival_date ? addDay = false : '';
                        })
                        addDay ? this.listDays.push({ day: String(booking._arrival_date), expanded: true, order: new Date(`${booking.arrival_date} ${arrivalTime}`) }) : '';
                        
                    //Reserva de dia anterior
                    } else {

                        this.beforeVisibleArrivals.push(booking);
                        this.listBeforeDay.forEach(day => {
                            day.day === booking._arrival_date ? addDay = false : '';
                        })
                        addDay ? this.listBeforeDay.push({ day: String(booking._arrival_date), expanded: true, order: new Date(`${booking.arrival_date} ${arrivalTime}`) }) : '';
                    }
                });
                this.visibleArrivals.sort((a, b) => 1 * (new Date(Date.parse(a._order)) - new Date(Date.parse(b._order))));
                console.log(this.visibleArrivals)
                this.beforeVisibleArrivals.sort((a, b) => 1 * (new Date(Date.parse(a._dayHour)) - new Date(Date.parse(b._dayHour))));
                this.listDays.sort((a, b) => 1 * (new Date(Date.parse(a.order)) - new Date(Date.parse(b.order))));
                this.listBeforeDay.sort((a, b) => 1 * (Date.parse(a.order) - Date.parse(b.order)));
            },

            formatDate(date) {
                const allMonths = this.translator.monthShort;
                const allWeekdays = this.translator.weekdayShort;
                const months = [
                    allMonths[0],
                    allMonths[1],
                    allMonths[2],
                    allMonths[3],
                    allMonths[4],
                    allMonths[5],
                    allMonths[6],
                    allMonths[7],
                    allMonths[8],
                    allMonths[9],
                    allMonths[10],
                    allMonths[11],
                ];
                const week = [
                    allWeekdays[0],
                    allWeekdays[1],
                    allWeekdays[2],
                    allWeekdays[3],
                    allWeekdays[4],
                    allWeekdays[5],
                    allWeekdays[6],
                ];

                let long_date = "";
                let day = date.slice(8, 10);
                let month = date.slice(5, 7);
                let year = date.slice(0, 4);

                //console.log(date + ', day: ' + day + ', month: ' + month + ', year: ' + year);

                let new_date = new Date(date);
                let day_week = new_date.getUTCDay();
                long_date =
                    week[day_week] + ", " + day + " " + months[month - 1] + " " + year;

                return long_date;
            },

            searchReservation: function (event) {
                this.searchReference = event.target.value;
                this.filterResults();
                document.getElementById(event.path[0].id).blur();
            },

            filterResults() {
                let result = this.arrivals.filter((booking) => {
                    let display = true;

                    //Busqueda por número de reserva o nombre
                    if (display) {
                        if (this.searchReference.length > 0) {
                            display = false;

                            let booking_name = booking.lodging_name.toLowerCase();
                            let booking_reservation = booking.reservation;
                            let ref_name = this.searchReference.toLowerCase();

                            if (
                                booking_name.indexOf(ref_name) > -1 ||
                                booking_reservation.indexOf(ref_name) > -1
                            ) {
                                display = true;
                            }
                        }
                    }
                    return display;
                });

                this.visibleArrivals = result;
            },
        },
        filters: {
            toLower(value) {
                if (!value) return "";
                value = value.toString();
                return value.toLowerCase();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cambiar-listado {
        width: inherit;
    }
</style>